import axios from 'axios';
import ApiBase from '@/api/baseApi';
import { errorHandler } from '@/helpers/errorHelper';
import StorageHelper from '@/helpers/localStorageHelper';
import {
  ILoginAuthResponse,
  ILoginResponse,
  IVerifyEmailAuthResponse,
} from '@/models/interfaces/auth/IAuth';

const authTokenTimeKey = 'auth_token_time';

class AuthAPI {
  static authTokenTimeKey() {
    return authTokenTimeKey;
  }

  static logout() {
    return new Promise((res, rej) => {
      try {
        axios({
          method: 'post',
          url: `${ApiBase.baseUrl()}/logout`,
          headers: {
            ...ApiBase.authHeaders(),
          },
        })
          .then((data: any) => {
            res(data);
          })
          .catch((error: any) => {
            rej(error);
          });
      } catch (error: any) {
        if (process.env.VUE_APP_ENVIRONMENT === 'development')
          console.error(error);
        rej(error);
      }
    });
  }

  static login(self: any) {
    const { form } = self;
    self
      .http('post', `${ApiBase.baseUrl()}/login`, form, null, { json: true })
      .then((response: ILoginResponse) => {
        self.isShow2FA = true;
        ApiBase.setToStore(null, 'setVerificationData', response);
        self.errors = {};
        self.$refs.setup2fa.makeFocusOnCode();
      })
      .catch((error: Error) => {
        errorHandler(self, error, {
          showNotification: true,
          notificationMessage: 'Wrong email or password',
        });
      });
  }

  static loginAuth(self: any, code: string) {
    const form = {
      code,
      email: self.state.verificationData.email,
    };
    self
      .http('post', `${ApiBase.baseUrl()}/login-auth`, form, null, {
        json: true,
      })
      .then(async (response: ILoginAuthResponse) => {
        await this.setAuthToken(response.token);
        self.$router.push({ name: 'Dashboard' }).catch(() => {
          /* empty */
        });
      })
      .catch((error: any) => {
        if (error.msg === 'Please login') {
          self.isShow2FA = false;
        }
        errorHandler(self, error, {
          showNotification: true,
          notificationMessage:
            error?.message || error?.msg || 'Something went wrong',
          throwError: false,
        });
      });
  }

  static register(self: any) {
    const { form } = self;
    self
      .http('post', `${ApiBase.baseUrl()}/registration`, form)
      .then(() => {
        self.showSuccessView = true;
      })
      .catch((error: Error) => {
        self.errors = error.message;
      });
  }

  static verifyEmail(self: any) {
    const { token } = self.$route.query;
    self
      .http('get', `${ApiBase.baseUrl()}/email-verification/${token}`)
      .then(({ valid }: any) => {
        self.isVerified = valid;
        self.finishedVerification = true;
        self.errorMessage = '';
        ApiBase.setToStore(null, 'setVerificationData', valid);
      })
      .catch(() => {
        self.isVerified = false;
        // self.errorMessage = (error.message as any).token;
        ApiBase.setToStore(null, 'setVerificationData', null);
      });
  }

  static createPassword(self: any) {
    const { token } = self.$route.query;
    const { form } = self;

    const data = {
      token,
      ...form,
    };

    self
      .http('post', `${ApiBase.baseUrl()}/email-verification`, data)
      .then((response: any) => {
        self.isVerified = true;
        self.errorMessage = '';
        ApiBase.setToStore(null, 'setVerificationData', response);
        self.$emit('next');
      })
      .catch((error: Error) => {
        errorHandler(self, error, {
          showNotification: true,
        });
      });
  }

  static verifySupportEmail(self: any) {
    const { token } = self.$route.query;
    self
      .http('get', `${ApiBase.baseUrl()}/support-email/${token}`)
      .then(() => {
        self.isVerified = true;
      })
      .catch((error: Error) => {
        self.isVerified = false;
        self.errorMessage = (error.message as any).token;
      });
  }

  static verifyEmailAuth(self: any, code: string) {
    const form = {
      code,
      email: self.state.verificationData.email,
    };

    self
      .http('post', `${ApiBase.baseUrl()}/email-verification-auth`, form)
      .then((response: IVerifyEmailAuthResponse) => {
        ApiBase.setToStore(
          null,
          'setBackupCodes',
          response.user.backup_codes.join(' ')
        );
        this.setAuthToken(response.token);
        self.isSuccess = true;
      })
      .catch((error: Error) => {
        const errorMessage = error.message;
        self.errorMessage = errorMessage;
        errorHandler(self, error, {
          showNotification: true,
          notificationMessage: errorMessage,
        });
      });
  }

  static sendResetPassword(self: any) {
    self
      .http('post', `${ApiBase.baseUrl()}/forgotten-password`, {
        email: self.email,
      })
      .then(() => {
        self.isShowSuccess = true;
        self.errorMessage = '';
      })
      .catch((error: Error) => {
        self.isShowSuccess = false;
        self.errorMessage = (error.message as any).email;
      });
  }

  static resetPassword(self: any) {
    const { token } = self.$route.query;
    self
      .http(
        'post',
        `${ApiBase.baseUrl()}/forgotten-password/${token}`,
        self.form
      )
      .then(() => {
        self.isSuccess = true;
        self.errors = {};
      })
      .catch((error: Error) => {
        self.isSuccess = false;
        self.errors = error.message;
      });
  }

  static async setAuthToken(token: string) {
    const addedMins = 5;
    this.setTokenTime(addedMins);
    await StorageHelper.set(ApiBase.authTokenKey(), token);
  }

  static async setTokenTime(minutes: number) {
    const addedSeconds = minutes * 60000;
    let date = new Date();
    date = new Date(date.getTime() + addedSeconds);
    await StorageHelper.set(authTokenTimeKey, date.getTime());
  }
}

export default AuthAPI;
