import Vue from 'vue';

Vue.mixin({
  computed: {
    emailRules() {
      const emailFilter =
        /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
      return [
        (v: string) => !!v || 'Email is required',
        (v: string) => emailFilter.test(v) || 'Email must be valid',
        (v: string) => !/[а-яА-ЯЁё]/.test(v) || 'Email must not be Cyrillic',
      ];
    },
    emptyOrEmailRules() {
      const emailFilter =
        /^([a-zA-Z0-9_.+-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;

      return [
        (v: string) => {
          if (v?.length) {
            if (!emailFilter.test(v)) {
              return 'Email must be valid';
            }

            if (/[а-яА-ЯЁё]/.test(v)) {
              return 'Email must not be Cyrillic';
            }
          }

          return true;
        },
      ];
    },
    passwordRules() {
      return [
        (v: string) =>
          !!v.match(/[A-Z]/)?.length ||
          'The password must contain at least 1 uppercase letter',

        (v: string) =>
          !!v.match(/[0-9]/)?.length ||
          'The password must contain at least 1 number',
        (v: string) => !!v || 'Password is required',
        (v: string) =>
          v?.length >= 8 || 'Password must be at least 8 characters',
        (v: string) =>
          v?.length <= 64 ||
          'The password must be no more than 64 characters long.',
      ];
    },
  },
  methods: {
    checkForSpecialChar(string: string) {
      // eslint-disable-next-line
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
      return format.test(string);
    },
    checkIfStringHasUppercase(string: string) {
      if (!string) return false;
      let i = 0;
      while (i <= string?.length) {
        const character = string.charAt(i);
        if (
          character === character.toUpperCase() &&
          character !== character.toLowerCase()
        ) {
          return true;
        }
        i += 1;
      }
      return false;
    },
    passwordConfirmationRules(password: string) {
      return [
        (v: string) => !!v || 'auth_valid_rule_password_confirm_required',
        (v: string) =>
          v === password || 'auth_valid_rule_password_confirm_not_match',
      ];
    },
  },
});
