const icons: { [x: string]: string } = {
  BCH: 'bch.svg',
  BTC: 'bitcoin.svg',
  BTCB_BSC: 'btcb-bsc.svg',
  ETH: 'eth.svg',
  ADA: 'ada.svg',
  AUD: 'aud.svg',
  AVAX: 'avax.svg',
  BNB: 'bnb.svg',
  BNB_BSC: 'bnb.svg',
  'BNB BSC': 'bnb.svg',
  BUSD: 'busd.svg',
  BSC: 'bsc.svg',
  CAD: 'cad.png',
  DOGE: 'doge.svg',
  DOT: 'dot.svg',
  EUR: 'eur.svg',
  GBP: 'gbp.svg',
  HEX: 'hex.svg',
  JPY: 'jpy.svg',
  LTC: 'ltc.svg',
  LUNA: 'luna.svg',
  MATIC: 'matic.svg',
  SHIB: 'shib.svg',
  USD: 'usd.svg',
  USDT: 'usdt.svg',
  USDT_ERC20: 'usdt-erc.svg',
  USDT_ERC: 'usdt-erc.svg',
  USDTE: 'usdt-erc.svg',
  USDC: 'usdc.svg',
  USDCT: 'usdc-trx.svg',
  USDCE: 'usdc-erc.svg',
  USDC_ERC: 'usdc-erc.svg',
  USDC_ERC20: 'usdc-erc.svg',
  USDT_BSC: 'usdt-bnb.svg',
  WBTC: 'wbtc.svg',
  XRP: 'xrp.svg',
  TRX: 'tron.svg',
  TRX_USDT_S2UZ: 'usdt-trx.svg',
  TRX_USDT: 'usdt-trx.svg',
  TRX_USDC_SKL5: 'usdc-trx.svg',
  TRX_USDC: 'usdc-trx.svg',
  TRX_USDC_6NU3: 'usdc-trx.svg',
  TON: 'ton.svg',
  USDT_BNB: 'usdt-bnb.svg',
  USDT_TRC20: 'usdt-trx.svg',
  USDT_TRC: 'usdt-trx.svg',
  USDTT: 'usdt-trx.svg',
  USDT_TRX: 'usdt-trx.svg',
  UAH: 'uah.svg',
  RUB: 'rub.svg',
  BRL: 'brl.svg',
  SOL: 'sol.svg',
};

Object.entries(icons).forEach(([key, value]) => {
  icons[key] = value;
  icons[`${key}_TEST`] = value;

  for (const x of Array(1000).keys()) {
    icons[`${key}_${x}`] = value;
    icons[`${key}_TEST${x}`] = value;
    icons[`${key}_TEST_${x}`] = value;
  }
});

export default icons;
